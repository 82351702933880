exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cosas-js": () => import("./../../../src/pages/cosas.js" /* webpackChunkName: "component---src-pages-cosas-js" */),
  "component---src-pages-goldenrod-js": () => import("./../../../src/pages/goldenrod.js" /* webpackChunkName: "component---src-pages-goldenrod-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-manga-index-js": () => import("./../../../src/pages/reviews/manga/index.js" /* webpackChunkName: "component---src-pages-reviews-manga-index-js" */),
  "component---src-pages-reviews-manga-undead-unluck-js": () => import("./../../../src/pages/reviews/manga/undead-unluck.js" /* webpackChunkName: "component---src-pages-reviews-manga-undead-unluck-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

